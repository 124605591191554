import styled, { css } from 'styled-components';

const linearGradient = css`
  content: '';
  position: absolute;
  top: 5%;
  height: 90%;
  width: 1.2rem;
  transform: scaleX(0) matrix(1, 0, 0, -1, 0, 0);

  opacity: 1;
  transform-origin: center;
`;

export const CardBackground = styled.div`
  position: relative;
  background: transparent;
  opacity: 1;
  &::before,
  &::after {
    ${linearGradient}
  }

  &::before {
    left: -1.2rem;
    background: linear-gradient(
      360deg,
      rgba(244, 245, 246, 0) 0%,
      #ffffff 100%
    );
  }

  &::after {
    right: -1.2rem;
    background: linear-gradient(
      180deg,
      rgba(244, 245, 246, 0) 0%,
      #ffffff 100%
    );
  }

  &:hover {
    background: ${({ theme }) => theme.colors.neutral[0]};
    box-shadow: 48px 48px 104px -24px rgba(19, 26, 32, 0.08);
  }

  &:hover:before,
  &:hover:after {
    opacity: 1;
    transform: scaleX(1) matrix(1, 0, 0, -1, 0, 0);
  }
`;

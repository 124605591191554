import Card, { CardProps } from '@components/views/Card';
import FeaturedContent, {
  FeaturedContentProps,
} from '@components/views/FeaturedContent';
import Container from '@components/containers/Container';
import { IButtonACF } from 'src/types/IButtonACF';
import { CardsGrid } from '@components/views/CardsGrid';
import ButtonContainer from './ButtonContainer';
import clsx from 'clsx';

export interface CtaBannerProps extends FeaturedContentProps {
  benefits?: Array<CardProps>;
  button?: IButtonACF;
  hasDashedDecoration?: boolean;
}

/**
 *
 * @link https://www.figma.com/file/bEoq4a8N0Yy6hvVvwAQxiS/WSC-%3A%3A-UI-Design?node-id=318%3A4097
 */

const BenefitsSection: React.FunctionComponent<CtaBannerProps> = (props) => {
  const {
    benefits,
    button,
    hasDashedDecoration = true,
    heading,
    chip,
    description,
  } = props;

  const title = heading?.title;

  return (
    <section className="bg-neutral-1 mt-56 py-32 lg:pb-64 lg:pt-96 xl:pt-112 overflow-hidden">
      <Container>
        <div className="grid lg:gap-56 relative">
          <div
            className={clsx('grid items-center gap-24 py-24 lg:py-0', {
              'lg:grid-cols-2': title,
            })}
          >
            {title && (
              <FeaturedContent
                heading={heading}
                description={description}
                chip={chip}
              />
            )}
            <ButtonContainer button={button} />
          </div>
          {benefits && benefits.length > 0 && (
            <CardsGrid>
              {benefits.map((card, i) => (
                <Card
                  {...card}
                  key={card.title}
                  hasDecorator={hasDashedDecoration && i === 0}
                />
              ))}
            </CardsGrid>
          )}
        </div>
      </Container>
    </section>
  );
};

export default BenefitsSection;

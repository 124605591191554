import styled from 'styled-components';
import { mediaQueries } from '@styles/theme';

export const CardsGrid = styled.div`
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-gap: 1.6rem;

  ${mediaQueries.greaterThan('md')`
    grid-template-columns: 1fr 1fr;
    grid-gap: 6.4rem;
  `}

  ${mediaQueries.greaterThan('lg')`
    grid-template-columns: repeat(auto-fit, minmax(26rem, 40rem))
    grid-gap: 4rem;
  `}


   ${mediaQueries.greaterThan('xl')`
    grid-gap: 8rem;
    grid-template-columns: repeat(4,1fr)
  `}
`;

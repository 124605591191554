import Image from 'next/image';
import React from 'react';
import { CardBackground } from './CardBackground.style';
import DashedLineSVG from '../../../assets/images/dashed-line.svg';

export interface CardProps {
  title?: string;
  description?: string;
  icon?: string;
  hasDecorator?: boolean;
}

const Card: React.FunctionComponent<CardProps> = (props) => {
  const { title, icon, description, hasDecorator } = props;

  const Icon = icon && (
    <div className="group-hover:bg-neutral-0 bg-neutral-1 inline-flex items-center z-10 relative">
      <Image src={icon} width={36} height={36} alt="" />
    </div>
  );

  return (
    <CardBackground className="outer-tile group py-24 lg:py-56 px-12 -mx-12 md:px-24 md:-mx-24 flex flex-col h-full justify-between">
      <article>
        <div className="mb-6 md:mb-0">
          {icon && (
            <div className="md:hidden mr-16 float-left md:float-none">
              {Icon}
            </div>
          )}
          {title && (
            <div className="text-xl font-medium pr-8">
              <p
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
            </div>
          )}
        </div>
        {description && (
          <p
            className="text-neutral-5 pt-8"
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        )}
      </article>
      {icon && (
        <div className="hidden md:block mt-32 justify-self-end relative ">
          {Icon}
          {hasDecorator && (
            <div className="absolute bottom-1/2 -translate-y-1/2 left-0 right-0 z-0">
              <DashedLineSVG />
            </div>
          )}
        </div>
      )}
    </CardBackground>
  );
};

export default Card;

import React from 'react';
import { IButtonACF } from 'src/types/IButtonACF';
import Button from '@components/views/Button';
import PatternSVG from '../../../assets/images/pattern.svg';

export interface ButtonContainerProps {
  button?: IButtonACF;
}

const ButtonContainer: React.FunctionComponent<ButtonContainerProps> = ({
  button,
}) => {
  return (
    <div className="grid items-start lg:justify-end h-full relative overflow-hidden">
      <div className="absolute w-full h-full -top-full lg:top-0 right-0 hidden lg:flex justify-end">
        <PatternSVG />
      </div>
      {button && (
        <div className="z-10">
          <Button href={button.url} variant={button.variant || 'PRIMARY'}>
            {button.title}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ButtonContainer;
